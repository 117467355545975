<template>
    <div>
        <v-card v-if="loadingPPECBData">
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12" md="12">

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
        <v-card flat v-else>
            <v-card-text>
                <v-row align="center" no-gutters style="margin-bottom: 20px">
                    <v-col cols="6" sm="6" md="1">
                        <h4 class="primaryText--text">
                            Vessel: <br />
                            Voyage:
                        </h4>
                    </v-col>
                    <v-col cols="6" sm="6" md="1">
                        <v-row align="center">
                            {{ ppecbData[0].vessel }}
                            <br />
                            {{ ppecbData[0].voyage }}
                        </v-row>
                    </v-col>
                    <v-col cols="6" sm="6" md="1">
                        <h4 class="primaryText--text">Product:
                            <br />
                            PPECB Code:
                        </h4>
                    </v-col>
                    <v-col cols="6" sm="6" md="1">
                        <v-row align="center">
                            {{ ppecbData[0].product }}
                            <br />
                            {{ ppecbData[0].ppecbCode }}
                        </v-row>
                    </v-col>

                    <v-col cols="6" sm="6" md="1">
                        <h4 class="primaryText--text">
                            Vent settings: <br />
                            Set Point:
                        </h4>
                    </v-col>
                    <v-col cols="6" sm="6" md="1">
                        <v-row align="center">
                            {{ ppecbData[0].ventSetting }}
                            <br />
                            {{ ppecbData[0].setPoint }}
                        </v-row>
                    </v-col>
                    <v-col cols="6" sm="6" md="1">
                        <h4 class="primaryText--text">
                            EX1: <br />
                            EX2:
                        </h4>
                    </v-col>
                    <v-col cols="6" sm="6" md="1">
                        <v-row align="center">
                            {{
                                ppecbData[ppecbData.length - 1].ex1
                            }}
                            <br />
                            {{ ppecbData[ppecbData.length - 1].ex2 }}
                        </v-row>

                    </v-col>
                    <v-col cols="6" sm="6" md="1">
                        <h4 class="primaryText--text">
                            EX3: <br />
                        </h4>
                    </v-col>
                    <v-col cols="6" sm="6" md="1">
                        <v-row align="center">
                            {{ ppecbData[ppecbData.length - 1].ex3 }}
                        </v-row>
                    </v-col>
                </v-row>


            </v-card-text>
        </v-card>
        <v-container grid-list-xl>
            <!-- <v-row>
                <v-col>
                    <v-flex>
                        <TempLogsTemperature :item="item" />
                    </v-flex>
                </v-col>
                <v-col>
                    <v-flex>
                        <PortMonitoringTemp :item="item" />
                    </v-flex>
                </v-col>
            </v-row> -->
            <v-row>
                <v-col>
                    <v-flex>
                        <SensorTemperature :container="item" :monitoringData="monitoringData" />
                    </v-flex>
                </v-col>
                <v-col>
                    <v-flex>
                        <!-- <SensorLight :container="item" /> -->
                    </v-flex>
                </v-col>
            </v-row>
        </v-container>
    </div>

</template> 

<script>
import SensorTemperature from '../../Telemetry/SensorTemperature.vue';
// import SensorLight from '../../Telemetry/SensorLight.vue';
// import TempLogsTemperature from '../../Telemetry/TempLogsTemperature.vue';
// import PortMonitoringTemp from '../../Telemetry/PortMonitoringTemp.vue';
export default {
    props: ['item'],
    components: { 
        SensorTemperature, 
        // SensorLight, 
        // TempLogsTemperature ,PortMonitoringTemp
    },
    data: () => ({
        ppecbData:{},
        loadingPPECBData:true,
        portMonitoringData:{},
        loadingPortMonitoringData:true,
        monitoringData: {}
    }),
    watch: {

    },
    async created() {
        if(this.item.sensorId){
            this.monitoringData = await this.$API.getSensorDetails(this.item.sensorId)
        }
    },
    methods: {
    }
}
</script>

<style>

</style>